<template>
  <div class="solve">
    <div class="xzdhjjfa">
      <div class="xzdhjjfa_box">
        <p>工作日程解决方案</p>
        <p>工作千头万绪，试试这个智能安排工作的利器</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="nsfjd">
      <div class="nsfjd_box">
        <p>您是否觉得工作越来越乱，没有助手帮忙整理？</p>
        <img src="@/assets/images/solve/gzrcjjfa/nsfjd.png" />
        <div class="nsfjd_box_item">
          <div class="nsfjd_box_item_column">
            <div class="nsfjd_box_item_content">
              <p>待办工作多</p>
              <p>待办事项多而杂乱，缺乏统一整理排序， 容易忘事</p>
            </div>
          </div>
          <div class="nsfjd_box_item_column">
            <div class="nsfjd_box_item_content">
              <p>会议时间乱</p>
              <p>会议安排多，没有会议通知和提醒，只能 自己写备忘录</p>
            </div>
            <div class="nsfjd_box_item_content">
              <p>工作统筹难</p>
              <p>统筹工作时才知道同事或领导请休假了， 工作时协同性差</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gzrc">
      <p>工作日程，更懂领导的“智能小助手”</p>
      <p>
        工作日程主要实现单位日程（日常工作、日常会议、定期工作、催办事项）、个人日程（日常工作、定期工作、记事本），
        休假日程、外出日程、信访日程、值班日程安排。提前一天下午五点半短信通知，也可手动发送短信通知。
      </p>
      <img src="@/assets/images/solve/gzrcjjfa/gzrc.png" />
    </div>
    <div class="ylgzrch">
      <p>有了工作日程后，您的一天有了改变</p>
      <div class="ylgzrch_box">
        <div
          class="ylgzrch_box_label"
          :class="{ select: state === index }"
          v-for="(item, index) in ylgzrch"
          :key="index"
          @mouseover="select(item, index)"
        >
          <img :src="item.img" />
          <p :class="{ selectText: state === index }">{{ item.label }}</p>
        </div>
      </div>
      <div class="ylgzrch_item">
        <p>{{ stateItem.title }}</p>
        <img :src="stateItem.img" />
      </div>
    </div>
    <div class="cgal">
      <p>成功案例，与你分享价值所在</p>
      <p>
        2012年起，佛山高明全区启用工作日程，实现区委、区政府及全区各部门重大会议活动的综合安排
      </p>
      <div class="cgal_box">
        <div class="cgal_box_item">
          <img
            class="icon"
            src="@/assets/images/solve/gzrcjjfa/cgal_icon1.png"
          />
          <p>以前</p>
          <p>政府统筹工作时沟通低效</p>
          <p>日常工作中易拖延、易忘事；难以科学地规划时间，安排事项</p>
          <img
            class="image"
            src="@/assets/images/solve/gzrcjjfa/cgal_icon3.png"
          />
        </div>
        <div class="cgal_box_item">
          <img
            class="icon"
            src="@/assets/images/solve/gzrcjjfa/cgal_icon2.png"
          />
          <p class="blue">现在</p>
          <p class="blue">所有日程自动整理提醒</p>
          <p class="blue">同步了解同事时间表，统筹工作简单多了</p>
          <img
            class="image"
            src="@/assets/images/solve/gzrcjjfa/cgal_icon4.png"
          />
        </div>
      </div>
    </div>
    <solve-footer />
  </div>
</template>

<script>
import SolveFooter from '@/components/SolveFooter'
import $ from 'jquery'
export default {
  data () {
    return {
      state: 0,
      ylgzrch: [
        {
          state: 0,
          img: require('@/assets/images/solve/gzrcjjfa/ylgzrch_icon0_ok.png'),
          label: '早上'
        },
        {
          state: 1,
          img: require('@/assets/images/solve/gzrcjjfa/ylgzrch_icon1.png'),
          label: '中午'
        },
        {
          state: 2,
          img: require('@/assets/images/solve/gzrcjjfa/ylgzrch_icon2.png'),
          label: '傍晚'
        }
      ],
      stateItem:
      {
        img: require('@/assets/images/solve/gzrcjjfa/ylgzrc_item0.png'),
        title: '打开OA，查看今天需参加的会议及待办工作，一天的工作就从清晰的日程安排开始'
      }
    }
  },
  methods: {
    slide () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.nsfjd_box_item_content').offset().top
      if (h > targetHeight - 300) {
        $('.nsfjd_box_item_content').addClass('animation')
      }
    },
    select (item, index) {
      this.state = index
      this.ylgzrch.forEach(element => {
        if (item.label === element.label) {
          element.img = require('@/assets/images/solve/gzrcjjfa/ylgzrch_icon' + +index + '_ok.png')
        } else {
          element.img = require('@/assets/images/solve/gzrcjjfa/ylgzrch_icon' + +element.state + '.png')
        }
      })
      var title = ['打开OA，查看今天需参加的会议及待办工作，一天的工作就从清晰的日程安排开始', '统筹工作、安排会议时，打开工作日程直观地查看同事的请休假状态，不必反复沟通协调', '下午5：30，智能助手已把次日的工作安排和会议安排整理好，并发到您的手机上，随时可在手机上查看日程。']
      this.stateItem.img = require('@/assets/images/solve/gzrcjjfa/ylgzrc_item' + +index + '.png')
      this.stateItem.title = title[index]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.slide)
    setTimeout(() => {
      $('.nsfjd_box_item_content').addClass('slide')
    }, 100)
  },
  destroyed () {
    window.addEventListener('scroll', this.slide)
  },
  components: {
    SolveFooter
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
